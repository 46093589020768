<template>
  <main>
    <Header />
    <slot />
  </main>
</template>

<script setup lang="ts">
import Header from "@/components/Layout/Header.vue";
import { useUserModeStore } from "@/stores/useUserModeStore";

const userModeStore = useUserModeStore();

onMounted(() => {
  userModeStore.mountUserMode();
});
</script>
