<template>
  <div class="burger" :class="{ 'burger--active': menuIsOpen }">
    <button @click="setMenuIsOpen(true)" class="burger__button">
      <v-icon>mdi-menu</v-icon>
    </button>

    <div class="burger-blur"></div>

    <div ref="target" class="burger__content">
      <div class="burger__content-header">
        <button class="burger__content-back" @click="setMenuIsOpen(false)">
          <v-icon>mdi-arrow-left</v-icon>
        </button>

        <nuxt-link to="/"><IconLogo /></nuxt-link>
      </div>

      <div class="burger__content-body">
        <div>
          <nuxt-link
            class="burger__account"
            :to="userModeStore.getBurgerAccount.link"
            @click="setMenuIsOpen(false)"
          >
            <Avatar :size="40" :src="userModeStore.getBurgerAccount.avatar" />
            <div class="burger__account-info">
              <span>{{ userModeStore.getBurgerAccount.name }}</span>
              <div class="burger__account-info-desc">
                <span class="burger__account-info-rating"
                  ><v-rating
                    v-model="userModeStore.getBurgerAccount.rating"
                    density="compact"
                    :active-color="'#FB8C00'"
                    :length="1"
                  ></v-rating>
                  {{ userModeStore.getBurgerAccount.rating }}
                </span>
                <span class="burger__account-info-comments">
                  {{ userModeStore.getBurgerAccount.comments }} відгуки
                </span>
              </div>
            </div>
            <v-icon class="burger__account-arrow">mdi-arrow-right</v-icon>
          </nuxt-link>

          <ul class="burger__content-list">
            <li
              v-for="item in userModeStore.getBurgerLinks"
              :key="item.id"
              class="burger__content-link"
            >
              <nuxt-link :to="item.link" @click="setMenuIsOpen(false)">
                {{ item.name }}
                <v-icon>mdi-arrow-right</v-icon>
              </nuxt-link>
            </li>
          </ul>
        </div>

        <div v-if="sellerStore.isSeller">
          <v-btn
            class="burger__content-button"
            size="large"
            color="black"
            block
            @click="userModeStore.changeMode()"
            variant="flat"
          >
            {{ userModeStore.getChangeButtonText }}
          </v-btn>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { onClickOutside } from "@vueuse/core";
import IconLogo from "@/components/Icons/IconLogo.vue";
import Avatar from "@/components/Avatar.vue";
import { useUserModeStore } from "@/stores/useUserModeStore";
import { useSellerStore } from "@/stores/useSellerStore";

const target = ref(null);
const menuIsOpen = ref(false);
const userStore = useUserStore();
const userModeStore = useUserModeStore();
const sellerStore = useSellerStore();

const setMenuIsOpen = (value: boolean) => {
  const body = document.querySelector("body");
  menuIsOpen.value = value;

  body.style.position = value ? "fixed" : "static";
};

onClickOutside(target, () => {
  setMenuIsOpen(false);
});
</script>

<style scoped lang="scss">
.burger {
  position: relative;

  &-blur {
    position: fixed;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    content: "";
    background: var(--color-main-dark);
    opacity: 0;
    pointer-events: none;
    z-index: var(--blur-z-index);
    transition: opacity 0.3s;
  }

  &--active {
    .burger__content {
      transform: translateX(0);
    }

    .burger-blur {
      pointer-events: all;
      opacity: 0.5;
    }
  }

  &__button {
    color: #fff;
  }

  &__content {
    width: 305px;
    height: 100vh;
    background: var(--color-main-white);
    position: fixed;
    top: 0;
    left: 0;
    transform: translateX(-305px);
    transition: transform 0.3s;
    z-index: var(--popup-z-index);
    padding: 20px 24px;
  }

  &__content-body {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: calc(100vh - 150px);

    padding-bottom: 80px;
  }

  &__content-header {
    display: flex;
    align-items: center;
    margin-bottom: 40px;

    svg {
      margin-left: 56px;
    }
  }

  &__content-list {
    list-style: none;
    padding: 0;
    display: flex;
    flex-direction: column;
    gap: 24px;
    margin-top: 32px;
  }

  &__content-link a {
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: var(--color-primary-black);
    text-decoration: none;
    letter-spacing: 0.08px;
  }

  &__account {
    display: flex;
    align-items: center;
    gap: 12px;
    text-decoration: none;
    color: var(--color-primary-black);
  }

  &__account-info {
    display: flex;
    flex-direction: column;
    gap: 8px;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 14px;
    letter-spacing: 0.048px;
  }

  &__account-arrow {
    margin-left: auto;
  }

  &__content-button {
    margin-top: auto;
  }

  &__account-info-desc {
    display: flex;
    align-items: center;
  }

  &__account-info-rating {
    display: flex;
    align-items: center;
    margin-right: 24px;
    color: var(--color-primary-black);
    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: 0.048px;
  }

  &__account-info-comments {
    color: var(--color-primary-black);
    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 14px;
    letter-spacing: 0.048px;
  }
}
</style>
