<template>
  <div class="entered-popup" :class="{ 'entered-popup--active': popIsOpen }">
    <div v-if="userStore.isAuth" class="entered-popup__content">
      <nuxt-link
        class="entered-popup__content-link"
        :to="userModeStore.getAccountLink.link"
      >
        {{ userModeStore.getAccountLink.name }}
        <v-icon class="entered-popup__content-icon">mdi-chevron-right</v-icon>
      </nuxt-link>

      <button class="entered-popup__content-link" @click="logout">
        Вихід
        <v-icon class="entered-popup__content-icon">mdi-logout</v-icon>
      </button>
    </div>
    <div v-else class="entered-popup__content">
      <span class="entered-popup__content-text">
        Увійдіть або зареєструйтесь щоб продовжити користуватись сайтом.
      </span>
      <nuxt-link class="entered-popup__content-link" to="/login">
        Увійти
        <v-icon class="entered-popup__content-icon">mdi-chevron-right</v-icon>
      </nuxt-link>

      <nuxt-link class="entered-popup__content-link" to="/register">
        Зареєструватись
        <v-icon class="entered-popup__content-icon">mdi-chevron-right</v-icon>
      </nuxt-link>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useUserModeStore } from "@/stores/useUserModeStore";

const userModeStore = useUserModeStore();
const props = defineProps({
  popIsOpen: {
    type: Boolean,
    required: true,
  },
});

const userStore = useUserStore();
const { logout } = useSanctumAuth();
</script>

<style scoped lang="scss">
.entered-popup {
  position: absolute;
  width: 100%;
  height: calc(100vh - var(--header-default-height));
  right: 0;
  top: -100vh;
  background: var(--color-main-white);
  z-index: 20;
  transition: 0.3s;

  @media (max-width: 1264px) {
    right: 0;
  }

  @media (min-width: 960px) {
    width: 300px;
    height: auto;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.15);
  }

  &--active {
    top: 0;
  }

  &__content {
    padding: 40px 24px;
    display: flex;
    flex-direction: column;
    gap: 24px;
  }

  &__content-text {
    color: var(--color-primary-black);
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0.2px;
    margin-bottom: 12px;

    @media (min-width: 960px) {
      display: none;
    }
  }

  &__content-link {
    display: flex;
    align-items: center;
    color: var(--color-primary-black);
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0.08px;
  }

  &__content-icon {
    margin-left: auto;
  }
}
</style>
